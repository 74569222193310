var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":"监督详情"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"上报内容","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('a-textarea',{attrs:{"auto-size":{ minRows: 3 },"value":_vm.text.content,"disabled":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"上报人联系方式"}},[_c('a-input',{attrs:{"value":_vm.text.reporterContact,"disabled":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"上报时间"}},[_c('a-input',{attrs:{"value":_vm.text.reporteTime,"disabled":""}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"图片","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},_vm._l((_vm.imgList),function(item,index){return _c('img',{key:index,staticStyle:{"margin":"10px"},attrs:{"width":"200px","src":item,"alt":""}})}),0)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"处理状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'status',
            {
              initialValue: _vm.text.status,
              rules: [{ required: true, message: '请选择' }],
            },
          ]),expression:"[\n            'status',\n            {\n              initialValue: text.status,\n              rules: [{ required: true, message: '请选择' }],\n            },\n          ]"}]},_vm._l((_vm.statusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"处理说明","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remark',
            {
              initialValue: _vm.text.remark,
              rules: [{ required: true, message: '请输入' }],
            },
          ]),expression:"[\n            'remark',\n            {\n              initialValue: text.remark,\n              rules: [{ required: true, message: '请输入' }],\n            },\n          ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"处理人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'processor',
            {
              initialValue: _vm.text.processor,
              rules: [
                {
                  required: true,
                  message: '请输入',
                },
              ],
            },
          ]),expression:"[\n            'processor',\n            {\n              initialValue: text.processor,\n              rules: [\n                {\n                  required: true,\n                  message: '请输入',\n                },\n              ],\n            },\n          ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"处理时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'processTime',
            {
              rules: [{ required: true, message: '请输入' }],
            },
          ]),expression:"[\n            'processTime',\n            {\n              rules: [{ required: true, message: '请输入' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"show-time":"","format":"YYYY-MM-DD HH:mm:ss"}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }