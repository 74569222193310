<template>
  <Card title="监督详情">
    <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :colon="false">
      <a-row>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="上报内容" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <a-textarea :auto-size="{ minRows: 3 }" :value="text.content" disabled />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="上报人联系方式">
            <a-input :value="text.reporterContact" disabled />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="上报时间">
            <a-input :value="text.reporteTime" disabled />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="图片" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <img width="200px" :src="item" alt v-for=" (item, index) in imgList" :key="index" style="margin:10px" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="处理状态">
            <a-select v-decorator="[
              'status',
              {
                initialValue: text.status,
                rules: [{ required: true, message: '请选择' }],
              },
            ]">
              <a-select-option :value="item.value" :key="item.value" v-for="item in statusList">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="处理说明" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <a-textarea :auto-size="{ minRows: 3 }" v-decorator="[
              'remark',
              {
                initialValue: text.remark,
                rules: [{ required: true, message: '请输入' }],
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="处理人">
            <a-input v-decorator="[
              'processor',
              {
                initialValue: text.processor,
                rules: [
                  {
                    required: true,
                    message: '请输入',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="处理时间">
            <a-date-picker style="width: 100%" v-decorator="[
              'processTime',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]" show-time format="YYYY-MM-DD HH:mm:ss" />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" html-type="submit">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </Card>
</template>

<script>
import { fetchDetail, edit } from "@/api/supervise";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      userList: [],
      text: {},

      id: "",
      imgList: [], //图片列表
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("superviseStatus");
    },
  },

  mounted() {
    const { id } = this.$route.query;
    if (!id) {
      return;
    }

    this.id = id || "";
    this.getDetail();
  },

  methods: {
    moment,
    getDetail() {
      fetchDetail({ id: this.id }).then((res) => {
        if (!res.id) {
          return;
        }

        this.text = {
          ...res,
        };

        if (res.pictures) {
          this.imgList = res.pictures
            .split(",")
            .map((item) => "http://" + item);
        }

        if (res.processTime) {
          this.form.setFieldsValue({
            processTime: moment(res.processTime),
          });
        }
        console.log("this.imgList", this.imgList)
      });
    },
    cancel() {
      this.$router.go(-1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          edit({
            id: this.id,
            ...values,
            processTime: values.processTime.format("YYYY-MM-DD HH:mm:ss"),
          }).then(() => {
            this.$router.go(-1);
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  margin: 60px 0 20px;
}
</style>
